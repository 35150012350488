import * as React from "react";
import { css, withTheme } from "@delight-js/react";
import Link from "./Link";
import { useSpring, useSprings, animated } from "@react-spring/web";
import Grid, { Row, Cell } from "./layout/Grid";
import { H2, Subtitle1 } from "./texts";

const to = (show) => (item, index) => ({
  x: show ? "0vw" : "-100vw",
  y: 0,
  delay: index * 50,
});

const LinkBranch = withTheme(({ theme, defaultSubmenuClosed, link }) => {
  const [showChildren, setShowChildren] = React.useState(false);

  React.useEffect(() => {
    if (defaultSubmenuClosed) {
      setShowChildren(false);
    }
  }, [defaultSubmenuClosed]);

  const toggleChildren = () => setShowChildren((prev) => !prev);
  return (
    <React.Fragment>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <H2
          url={link.path}
          as={Link}
          css={css`
          display: flex;
          align-items: center;
        flex-grow: 2;
      padding-top: {8,8,,,,24}({"step": 8, "method": "floor"})px;
      padding-bottom: {8,8,,,,24}({"step": 8, "method": "floor"})px;

      &:after {
          content: "";
          flex-grow: 2;
          margin-left: 16px;
          height: calc(50% - 0.5px);
          border-bottom: solid 1px $color-spot1;
      }
`(theme)}
        >
          <span>{link.mobileTitle || link.title}</span>
        </H2>
        {!!link.children && (
          <button
            onClick={toggleChildren}
            css={css`
              position: relative;
              margin: -12px;
              width: 48px;
              height: 48px;
              padding: 0;
              border: 0;
              background: transparent;
              cursor: pointer;
              text-align: center;
              color: $color-spot1;

              &:before {
                content: "";
                position: absolute;
                width: 24px;
                height: 24px;
                padding: 0;
                border: solid 1px $color-spot1;
                border-radius: 24px;
                left: 12px;
                top: 12px;
                display: block;
                box-sizing: border-box;
              }
            `(theme)}
          >
            {showChildren ? "–" : "+"}
          </button>
        )}
      </div>
      {!!link.children && !!showChildren && (
        <ul
          css={css`
            list-style: none;
            margin: 0;
            padding: 0 0 12px;

            > li {
              margin: 0;
              padding: 0;
            }
          `}
        >
          {link.children.map((item, index) => (
            <li key={index}>
              <Link
                url={item.path}
                css={css`
                  font-family: $font-subline;
                  display: block;
                  padding: 8px 0 8px 24px;
                `(theme)}
              >
                {item.mobileTitle || item.title}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </React.Fragment>
  );
});

const MobileMenu = withTheme(({ theme, show, items = [], ...props }) => {
  const mainLinks = items.filter((i) => i.inNav);
  const appendixLinks = items.filter((i) => !i.inNav);
  const wrapperProps = useSpring({
    display: show ? "block" : "none",
    delay: show ? 0 : (mainLinks.length + 1) * 50,
  });
  const springs = useSprings(
    mainLinks.length + 1,
    Array.from({ length: items.length + 1 }).map(to(show))
  );

  return (
    <animated.div
      css={css`
        position: fixed;
        left: 0;
        top: 0;
        padding-top: 50px;
        width: 100vw;
        height: calc(100vh - 50px);
        overflow-y: auto;
        overscroll-behavior: auto;
      `(theme)}
      style={wrapperProps}
      {...props}
    >
      <Grid
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
        `(theme)}
      >
        <Row
          css={css`
            height: 100%;
          `}
        >
          <Cell span={{ default: 8 / 12 }} offset={{ default: 1 / 12 }}>
            <ul
              css={css`
                list-style: none;
                margin: 0;
                padding: 0;
                height: 100%;
                display: flex;
                flex-direction: column;

                > li {
                  margin: 0;
                  padding: 0;
                }
              `}
            >
              {springs.map((props, index, { length }) => {
                if (index === length - 1) {
                  return null;
                }
                return (
                  <animated.li key={index} style={props}>
                    <LinkBranch
                      link={mainLinks[index]}
                      defaultSubmenuClosed={show}
                    />
                  </animated.li>
                );
              })}
              <animated.li
                style={springs[springs.length - 1]}
                css={css`
                  flex-grow: 2;
                  display: flex;
                  align-items: flex-end;
                `}
              >
                <Subtitle1
                  css={css`
                    $mt20;
                    $mb20;
                    display: flex;
                  `(theme)}
                >
                  {appendixLinks.map((link, index) => (
                    <Link
                      key={index}
                      url={link.path}
                      css={
                        index
                          ? css`
                              position: relative;
                              box-shadow: none;
                              margin-left: 24px;

                              &:hover {
                                box-shadow: none;
                              }
                              &:before {
                                content: "";
                                position: absolute;
                                left: -12px;
                                top: 0.4em;
                                height: calc(100% - 0.8em);
                                border-left: solid 1px $color-spot1;
                              }
                            `(theme)
                          : css`
                              box-shadow: none;

                              &:hover {
                                box-shadow: none;
                              }
                            `
                      }
                    >
                      {link.mobileTitle || link.title}
                    </Link>
                  ))}
                </Subtitle1>
              </animated.li>
            </ul>
          </Cell>
        </Row>
      </Grid>
    </animated.div>
  );
});

export default MobileMenu;
