/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
// import "./src/styles/fonts/fonts.css";
import { createRootWrapper } from "./gatsby-shared";
export { wrapPageElement } from "./gatsby-ssr";

export const wrapRootElement = createRootWrapper({ isSsr: false });

const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  if (prevRouterProps) {
    window.scrollTo(0, routerProps?.location?.state?.scrollPosition || 0);
    return false;
  }
  return true;
};

export { shouldUpdateScroll };
