/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/ssr-apis/
 */

import * as React from "react";
import { Helmet } from "react-helmet";
import Watcher from "@fuckingdigital/watch-animation-frame";
import { Global, ThemeProvider } from "@delight-js/react";
import { css } from "@emotion/react";
import theme from "./src/styles/theme";

import hlFont from "./src/styles/fonts/bagnard-webfont.woff";
import hlFont2 from "./src/styles/fonts/bagnard-webfont.woff2";
import hlPlusFont from "./src/styles/fonts/Bagnard-Plus.woff";
import hlPlusFont2 from "./src/styles/fonts/Bagnard-Plus.woff2";
import copyFont from "./src/styles/fonts/IBMPlexSans-Regular-Latin1.woff";
import copyFont2 from "./src/styles/fonts/IBMPlexSans-Regular-Latin1.woff2";
import monoFont from "./src/styles/fonts/Necto-Mono.woff";
import monoFont2 from "./src/styles/fonts/Necto-Mono.woff2";

const fonts = [
  {
    name: "Headline-Addons",
    woff: hlPlusFont,
    woff2: hlPlusFont2,
    atf: true,
    included: true,
  },
  { name: "Headline", woff: hlFont, woff2: hlFont2, atf: true },
  { name: "Mono", woff: monoFont, woff2: monoFont2, atf: true },
  { name: "Copy", woff: copyFont, woff2: copyFont2 },
];

const Fonts = () => (
  <React.Fragment>
    <Helmet>
      {fonts.reduce((allLinks, f) => {
        if (!f.atf) {
          return allLinks;
        }
        return [
          ...allLinks,
          ...["woff2", "woff"].map((type) => {
            const additionalProps = {};
            if (!f.included) {
              additionalProps.rel = "preload";
              additionalProps.as = "font";
              additionalProps.crossOrigin = "anonymous";
            }
            return (
              <link
                key={`${f.nametype}-${type}`}
                href={f[type]}
                type={`font/${type}`}
                {...additionalProps}
              />
            );
          }),
        ];
      }, [])}
    </Helmet>

    <Global
      styles={css`
        ${fonts
          .map(
            (f) => `
          @font-face {
            font-family: "${f.name}";
            src: url("${f.woff2}") format("woff2"),
              url("${f.woff}") format("woff");
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
        `
          )
          .join("\n")}
      `}
    />
  </React.Fragment>
);

export const createRootWrapper = ({ isSsr = true }) => ({ element }) => {
  if (typeof window !== "undefined" && !window.__WATCHERS__) {
    let watchers = {};
    watchers.scroll = new Watcher(() => window.scrollY);
    watchers.size = new Watcher(() => [window.innerWidth, window.innerHeight]);
    window.__WATCHERS__ = watchers;
  }
  return (
    <React.Fragment>
      {(isSsr || process.env.node_env !== "production") && <Fonts />}
      <ThemeProvider theme={theme} isSsr={isSsr}>
        {element}
      </ThemeProvider>
    </React.Fragment>
  );
};
