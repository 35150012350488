/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { css, withTheme } from "@delight-js/react";

import CookieBanner from "./CookieBanner";

import Header from "./Header";
import Footer from "./Footer";

const Layout = withTheme(({ theme, children, ...props }) => (
  <React.Fragment>
    {!!props?.pageContext && (
      <Header
        sitemap={props.pageContext.sitemap}
        meta={props.pageContext?.pageData?.meta || {}}
        {...props}
      />
    )}
    <main css={css`$pt4;`(theme)}>{children}</main>
    {!!props?.pageContext?.pageData && (
      <Footer
        locale={props.pageContext.pageData.locale}
        localizations={props.pageContext.localizations}
        sitemap={props.pageContext.sitemap}
      />
    )}
    {!!props?.pageContext?.pageData?.locale && (
      <CookieBanner locale={props.pageContext.pageData.locale} />
    )}
  </React.Fragment>
));

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
