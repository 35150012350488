import * as React from "react";
import { css, withTheme } from "@delight-js/react";
import { useSpring, animated } from "@react-spring/web";

const Veil = withTheme(({ theme, show, ...props }) => {
  const styles = useSpring({ scaleX: show ? 1 : 0, delay: show ? 0 : 100 });
  return (
    <animated.div
      css={css`
        position: fixed;
        left: -10px;
        top: 0;
        width: calc(100vw + 10px);
        height: 100vh;
        background-color: $color-spot2;
        transform-origin: left center;
        transform: scaleX(0);
      `}
      {...props}
      style={styles}
    />
  );
});

export default Veil;
