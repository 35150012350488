import configConfig from "./config";

const {
  tokens: { baseline },
} = configConfig;

const config = {
  domain: "space",
  createVariables: false, // TODO: if not set this should be an automation if theme contains more than one (“default”) key?
};
const tokens = {
  headline: "Headline",
  copy: "Copy",
  mono: "Mono",
};

const theme = {
  default: {
    1: "4px",
    2: "8px",
    3: "16px",
    4: "20px",
  },
};

const defs = {
  1: `{${1 * baseline},${1 * baseline},,,,${
    1 * baseline
  }}({"step": 8, "method": "round"})px`,
  "-1": `{${-1 * baseline},${-1 * baseline},,,,${
    -1 * baseline
  }}({"step": 8, "method": "round"})px`,
  2: `{${1 * baseline},${1 * baseline},,,,${
    2 * baseline
  }}({"step": 8, "method": "round"})px`,
  4: `{${2 * baseline},${2 * baseline},,,,${
    4 * baseline
  }}({"step": 8, "method": "round"})px`,
  6: `{${2 * baseline},${2 * baseline},,,,${
    6 * baseline
  }}({"step": 8, "method": "round"})px`,
  // 7: `{${3 * baseline},${3 * baseline},,,,${
  //   7 * baseline
  // }}({"step": 8, "method": "round"})px`,
  10: `{${3 * baseline},${3 * baseline},,,,${
    10 * baseline
  }}({"step": 8, "method": "round"})px`,
  14: `{${4 * baseline},${4 * baseline},,,,${
    14 * baseline
  }}({"step": 8, "method": "round"})px`,
  20: `{${10 * baseline},${10 * baseline},,,,${
    20 * baseline
  }}({"step": 8, "method": "round"})px`,
  26: `{${10 * baseline},${10 * baseline},,,,${
    26 * baseline
  }}({"step": 8, "method": "round"})px`,
};

const properties = [
  {
    property: "margin",
    short: "m",
  },
  {
    property: "padding",
    short: "p",
  },
];

const variants = [
  {
    type: "default",
    short: "",
    suffix: [""],
  },
  {
    type: "top",
    short: "t",
    suffix: ["top"],
  },
  {
    type: "right",
    short: "r",
    suffix: ["right"],
  },
  {
    type: "bottom",
    short: "b",
    suffix: ["bottom"],
  },
  {
    type: "left",
    short: "l",
    suffix: ["left"],
  },
  {
    type: "horizontal",
    short: "x",
    suffix: ["right", "left"],
  },
  {
    type: "vertical",
    short: "y",
    suffix: ["top", "bottom"],
  },
];

const styles = Object.entries(defs).reduce((styleAcc, [key, def]) => {
  const classes = properties.reduce((classAcc, { short, property }) => {
    const propVariants = variants.reduce(
      (propAcc, { short: variantShort, suffix }, index, original) => {
        const className = `${short + variantShort}${key}`;
        const classProps = suffix
          .map((s) => `${property}${s.length ? "-" : ""}${s}: ${def};`)
          .join(" ");
        return {
          ...propAcc,
          [className]: classProps,
        };
      },
      {}
    );
    return {
      ...classAcc,
      ...propVariants,
    };
  }, {});
  return {
    ...styleAcc,
    ...classes,
  };
}, {});

// from here on magic should happen
// TODO: do the next by a createTheme function

// const vars = createThemeVars(config.domain, theme, tokens);

const map = Object.entries(theme)[0][1];

const space = {
  config,
  tokens,
  theme,
  map,
  styles,
};

export default space;
