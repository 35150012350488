// import createThemeVars, { createMap } from "@delight-js/utils";

const config = {
  domain: "config",
  //   createUtilClasses: { prefix: "config-" }, // TODO: generate them
};

const tokens = {
  baseline: 8,
};

const theme = {
  default: {
    baseline: "baseline",
  },
};

const themeConfig = {
  config,
  tokens,
  theme,
  // vars,
  map: tokens,
};

export default themeConfig;
