/** @jsx jsx */
import { css, jsx, withTheme } from "@delight-js/react";
import Link from "./Link";
import { useStaticQuery, graphql } from "gatsby";

import Grid from "./layout/Grid";
import Row from "./layout/Row";
import Cell from "./layout/Cell";
import { H4, Caption } from "./texts";
import Button from "./Button";
import React from "react";

const Footer = withTheme(({ theme, sitemap, locale, localizations = [] }) => {
  const {
    site: {
      siteMetadata: { sharing },
    },
    allTranslation: { edges: translations },
  } = useStaticQuery(
    graphql`
      query FooterQuery {
        site {
          siteMetadata {
            sharing {
              name
              channel
            }
          }
        }
        allTranslation(filter: { meta: { domain: { eq: "components" } } }) {
          edges {
            node {
              meta {
                language
              }
              data {
                footer {
                  legal
                  contactButton
                  language
                }
              }
            }
          }
        }
      }
    `
  );
  const { legal, contactButton, language: chooseLanguage } =
    translations?.find((edge) => edge.node.meta.language === locale)?.node?.data
      ?.footer || {};
  const legalLinks = ["contact", "data-privacy", "imprint"];
  const links = legalLinks.reduce((acc, cur) => {
    const curPage = sitemap?.root?.find((p) => p.id === cur);
    if (curPage) {
      return {
        ...acc,
        [cur]: {
          to: curPage.path,
          children: curPage.title,
        },
      };
    }
    return acc;
  }, {});
  return (
    <Grid
      as="footer"
      css={css`
      $pt14;
      background-color: $color-bg2;

      a:not(:hover) {
        box-shadow: inset 0 0 0 0px $color-spot2;
      }
    `(theme)}
    >
      <Row>
        <Cell
          span={{ default: 10 / 12, md: 5 / 12, x2l: 3 / 12 }}
          offset={{ default: 1 / 12 }}
          css={css`
            $mb14;
            order: 0;
          `(theme)}
        >
          <H4 css={css`$mb1;`(theme)}>Eatery Berlin</H4>
          <Caption>
            Ben Donath
            <br />
            T: <Link url="tel:+4917663054781">+49 176 63 05 47 81</Link>
            <br />
            E:{" "}
            <Link
              css={css`
                user-select: none;
                &:before {
                  content: "post";
                }
                &:after {
                  content: "eryberlin.com";
                }
              `}
              to={links?.contact?.to}
            >
              @eat
            </Link>
          </Caption>
        </Cell>
        <Cell
          span={{ default: 10 / 12, md: 5 / 12, x2l: 3 / 12 }}
          offset={{ default: 1 / 12, x2l: 0 }}
          css={css`
            $mb14;
            order: 2;
          `(theme)}
        >
          <H4 css={css`$mb1;`(theme)}>Social Media</H4>
          <Caption>
            {sharing
              .filter((s) => s.channel)
              .map((s, index, { length }) => (
                <React.Fragment key={s.name}>
                  <Link href={s.channel}>{s.name}</Link>
                  {!!index < length - 1 && <br />}
                </React.Fragment>
              ))}
          </Caption>
        </Cell>
        <Cell
          span={{ default: 10 / 12, md: 5 / 12, x2l: 2 / 12 }}
          offset={{ default: 1 / 12, md: 0 }}
          css={css`
          $mb14;
          order: 2;
        `(theme)}
        >
          <H4 css={css`$mb1;`(theme)}>{legal}</H4>
          <Caption>
            {legalLinks.map((s, index, { length }) => (
              <React.Fragment key={s}>
                <Link {...links[s]} />
                {!!index < length - 1 && <br />}
              </React.Fragment>
            ))}
          </Caption>
        </Cell>
        <Cell
          span={{ default: 10 / 12, md: 5 / 12, x2l: 2.7 / 12 }}
          offset={{ default: 1 / 12, md: 0 }}
          css={css`
          $mb14;
          order: 1;
          @media:x2l {
            order: 2;
          }
        `(theme)}
        >
          <Button
            as={Link}
            to={links?.contact?.to}
            css={css`
              width: 100%;
            `}
            small
          >
            {contactButton}
          </Button>
          {localizations.length > 1 && (
            <div
              css={css`
                $mt4;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
              `(theme)}
            >
              <Caption
                as="span"
                css={css`
                $mr1;
                $my1;
              `(theme)}
              >
                {chooseLanguage}
              </Caption>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  flex-grow: 1;

                  > * {
                    flex-grow: 1;
                  }
                `}
              >
                {localizations.map((t, i) => (
                  <Button
                    as={Link}
                    to={t.url}
                    hrefLang={t.locale}
                    small
                    key={t.locale}
                    disabled={t.locale === locale}
                    css={i > 0 ? css`$ml1;`(theme) : {}}
                  >
                    <span lang={t.locale}>{t.languageText}</span>
                  </Button>
                ))}
              </div>
            </div>
          )}
        </Cell>
      </Row>
    </Grid>
  );
});

export default Footer;
