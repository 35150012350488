import * as React from "react";

const safeDocument = typeof document !== "undefined" ? document : {};
const { documentElement: html, body } = safeDocument;

const getScrollbarWidth = (innerWidth) =>
  (innerWidth ?? window.innerWidth) - html.clientWidth;

export { getScrollbarWidth };

const useScrollbarWidth = () => {
  const [scrollbarWidth, setScrollbarWidth] = React.useState();

  React.useEffect(() => {
    if (!window?.__WATCHERS__?.size || !body || !body.style) {
      return;
    }
    let scrollbarWatcher = window.__WATCHERS__.size.subscribe({
      onchange: ([width]) => {
        const scrollbarWidthNow = getScrollbarWidth(width);
        if (scrollbarWidth !== scrollbarWidthNow) {
          setScrollbarWidth(scrollbarWidthNow);
        }
      },
    });
    return () => {
      if (scrollbarWatcher) {
        window.__WATCHERS__.size.unsubscribe(scrollbarWatcher);
      }
    };
  }, [setScrollbarWidth, scrollbarWidth]);

  return scrollbarWidth;
};

export default useScrollbarWidth;
