import * as React from "react";
import { css, withTheme } from "@delight-js/react";
import Link from "./Link";
import useBlockScrolling from "../hooks/useBlockScrolling";
import useWindowWidth from "../hooks/useWindowWidth";

import { H4, Subtitle1 } from "./texts";
import Cell from "./layout/Cell";
import Grid from "./layout/Grid";
import Row from "./layout/Row";

import logoSvg from "../images/eatery-berlin-logo.svg";
import Seo from "./Seo";
import MenuButton from "./MenuButton";
import Veil from "./Veil";
import MobileMenu from "./MobileMenu";

const StyledLink = withTheme(({ children, submenu = [], theme, ...props }) => (
  <li
    css={css`
      display: none;
      position: relative;
      @media:xl {
        display: flex;
        align-items: baseline;
        &:before {
          content: "|";
          color: $color-spot1;
          display: block;
          position: absolute;
          left: -2.5px;
          top: 17px;
          height: 100%;
        }
      }

      > ul {
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        transform: scaleY(0);
        pointer-events: none;
        transform-origin: top center;
        transition: transform 0.1s;
        margin: 0;
        padding: 0;
        list-style: none;

        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          left: 0;
          right: 0;
          bottom: 0;
          top: 2px;
          backdrop-filter: blur(6px);
          background: rgba(255, 255, 255, 0.89);
        }

        li {
          margin: 0;
          padding-top: 0;
          white-space: nowrap;
        }
      }

      &:hover > ul {
        pointer-events: unset;
        transform: scaleY(1);
      }
    `(theme)}
  >
    <Subtitle1 as={Link} css={css`$p2;`} {...props}>
      {children}
    </Subtitle1>
    {!!submenu.length && (
      <ul>
        {submenu.map((item, index) => (
          <li key={index}>
            <Subtitle1
              as={Link}
              css={css`
                $pr2;
                $pb2;
                $pl2;
                display: block;
              `(theme)}
              url={item.path}
            >
              {item.title}
            </Subtitle1>
          </li>
        ))}
      </ul>
    )}
  </li>
));

const Header = withTheme(
  ({ theme, path, sitemap, meta, pageContext = {}, ...props }) => {
    const { pageData, localizations, pagination } = pageContext;
    const { title, locale } = pageData || {};

    const [menuOpen, setMenuOpen] = React.useState(false);
    const toggleScroll = useBlockScrolling();
    const { isSmallWindow } = useWindowWidth(1024);
    const toggleMenu = () => setMenuOpen((prev) => !prev);

    React.useEffect(() => {
      toggleScroll(menuOpen);
    }, [menuOpen, toggleScroll]);

    React.useEffect(() => {
      if (!isSmallWindow) {
        setMenuOpen(false);
      }
    }, [isSmallWindow]);

    React.useEffect(() => {
      setMenuOpen(false);
    }, [path]);

    return (
      <React.Fragment>
        <Seo
          meta={meta}
          title={title}
          lang={locale}
          localizations={localizations}
          pagination={pagination}
        />
        <header
          css={css`
            position: relative;
            z-index: 9999;
            min-height: 72px;
            @media:xl {
              position: fixed;
              right: 0;
              top: 0;
              left: 80px;
              min-height: 80px;
            }
            &:before {
              content: "";
              display: block;
              position: fixed;
              left: 0;
              top: 0;
              width: 10px;
              height: calc(100vh + 40px);
              background-color: $color-spot2;

              @media:xl {
                width: 80px;
              }
            }

            &:after {
              content: "";
              @media:xl {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                backdrop-filter: blur(6px);
                background: rgba(255, 255, 255, 0.89);
              }
            }

            a {
              color: $color-spot1;
              text-decoration: none;
              box-shadow: none;
              &:hover {
                box-shadow: none;
              }
            }
          `(theme)}
        >
          <Grid css={css(`position: relative;`)}>
            <Row>
              <Cell span={{ default: 10 / 12 }} offset={{ default: 1 / 12 }}>
                <ul
                  css={css`
                    position: relative;
                    top: 22px;
                    left: 0;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    align-items: baseline;

                    > li {
                      margin: 0;
                      padding: 0;
                    }
                  `(theme)}
                >
                  {!!sitemap?.root &&
                    sitemap.root
                      .filter((p) => !!p.inNav)
                      .map((l, index) =>
                        l.isHome ? (
                          <li
                            css={css`
                              margin-right: 1.25em !important;
                            `}
                            key={l.id}
                          >
                            <H4 css={css(`margin: 0;`)}>
                              <Link to={l.path}>
                                <img
                                  src={logoSvg}
                                  alt={l.title}
                                  width={39}
                                  height={39}
                                  css={css`
                                    display: inline-block;
                                    margin: -10px 5px -10px 0;
                                    width: 39px !important;
                                    height: 39px !important;

                                    @media:xl {
                                      position: fixed;
                                      left: 14px;
                                      top: 30px;
                                      width: 52px !important;
                                      height: 52px !important;
                                    }
                                  `}
                                />{" "}
                                {l.title}
                              </Link>
                            </H4>
                          </li>
                        ) : (
                          <StyledLink
                            to={l.path}
                            key={l.id}
                            submenu={l.children || []}
                          >
                            {l.title}
                          </StyledLink>
                        )
                      )}
                </ul>
              </Cell>
            </Row>
          </Grid>
          {/* Mobil menu veil */}
          <Veil
            css={css`
              z-index: 9990;
            `}
            show={menuOpen}
          />
          {/* Mobile Menu Button */}
          <MenuButton
            css={css`
              position: fixed;
              z-index: 9999;
              left: calc(100% - 10px - 48px);
              /* right: 10px; */
              top: 13px;
              @media:md {
                left: calc(100% - 8px - 64px);
                /* right: 8px; */
                top: 8px;
              }
              @media:xl {
                display: none;
              }
            `(theme)}
            switchedOn={menuOpen}
            onClick={toggleMenu}
          />
          {/* Mobile Menu */}
          <MobileMenu
            css={css`
              z-index: 9995;
            `}
            show={menuOpen}
            items={sitemap?.root}
          />
        </header>
      </React.Fragment>
    );
  }
);

export default Header;
