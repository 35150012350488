/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/ssr-apis/
 */

import * as React from "react";
import { createRootWrapper } from "./gatsby-shared";
import Layout from "./src/components/Layout";

export const wrapRootElement = createRootWrapper({ isSsr: true });

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

export const onRenderBody = ({ setPostBodyComponents }) => {
  setPostBodyComponents([<div key={"mouseportal"} id={"mouseportal"}></div>]);
};
