import isPropValid from "@emotion/is-prop-valid";
import { css, withTheme } from "@delight-js/react";
import styled from "@delight-js/styled";

const roundNumber = (value) => Math.floor(value * 100 * 100) / 100;

const width = (v) => `width: ${v}%;`;
const marginLeft = (v) => `margin-left: ${v}%;`;

const basicStyle = (props) =>
  css(`
box-sizing: border-box;
padding: calc($grid-gutter / 2);
flex-grow: unset;
`)(props.theme);

const spanStyle = ({ theme, span = {} }) => {
  // span rules
  const breakpointsInUse = Object.keys(span);
  let rules = "";
  if (!breakpointsInUse.includes("default")) {
    // default rule for smallest size
    rules += width(100);
  }
  breakpointsInUse.forEach((k) => {
    const v = span[k];
    if (k === "default") {
      rules += width(roundNumber(v));
    } else {
      rules += `@media:${k} { ${width(roundNumber(v))} }`;
    }
  });
  return css(rules)(theme);
};

const offsetStyle = ({ theme, offset = {} }) => {
  // offset rules
  const breakpointsInUse = Object.keys(offset);
  let rules = "";
  breakpointsInUse.forEach((k) => {
    const v = offset[k];
    if (k === "default") {
      rules += marginLeft(roundNumber(v));
    } else {
      rules += `@media:${k} { ${marginLeft(roundNumber(v))} }`;
    }
  });
  return css(rules)(theme);
};

const Cell = withTheme(styled("div", {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && !["span", "offset"].includes(prop),
})`
  ${basicStyle};
  ${spanStyle};
  ${offsetStyle};
`);

// {
//   return <div>{children</div>
//     ({ theme }) =>
//       css(`
//     box-sizing: border-box;
//     padding: calc($grid-gutter / 2);
//     flex-grow: unset;
//   `)(theme)
// ({ span, theme }) => {
//   // span rules
//   const breakpointsInUse = Object.keys(span);
//   const firstBreakpointAlias = theme.breakpointAlias[0];
//   const generateRule = (v) => `width: ${v}%;`;
//   let rules = "";
//   if (!breakpointsInUse.includes(firstBreakpointAlias)) {
//     // default rule for smallest size
//     rules += generateRule(100);
//   }
//   breakpointsInUse.forEach((k) => {
//     const v = span[k];
//     if (k === firstBreakpointAlias) {
//       rules += generateRule(roundNumber(v));
//     } else {
//       rules += mediaquery(k, generateRule(roundNumber(v)))(theme);
//     }
//   });
//   return css(rules);
// },
// ({ offset, theme }) => {
//   // offset rules
//   const breakpointsInUse = Object.keys(offset);
//   const firstBreakpointAlias = theme.breakpointAlias[0];
//   const generateRule = (v) => `margin-left: ${v}%;`;
//   let rules = "";
//   breakpointsInUse.forEach((k) => {
//     const v = offset[k];
//     if (k === firstBreakpointAlias) {
//       rules += generateRule(roundNumber(v));
//     } else {
//       rules += mediaquery(k, generateRule(roundNumber(v)))(theme);
//     }
//   });

//   return rules;
// };
//   )
// );

export default Cell;
