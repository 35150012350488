import * as React from "react";

const useWindowWidth = (breakpoint = 768) => {
  const [smallWindow, setSmallWindow] = React.useState(true);

  React.useEffect(() => {
    if (!window?.__WATCHERS__?.size) {
      return;
    }
    let dimensionWatcher = window.__WATCHERS__.size.subscribe({
      matchCondition: ([w, h]) => w >= breakpoint,
      onappear: () => setSmallWindow(false),
      ondisappear: () => setSmallWindow(true),
    });
    return () => {
      if (dimensionWatcher) {
        window.__WATCHERS__.size.unsubscribe(dimensionWatcher);
      }
    };
  }, [breakpoint, setSmallWindow]);

  return { isSmallWindow: !!smallWindow, isBigWindow: !smallWindow };
};

export default useWindowWidth;
