import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { css, withTheme } from "@delight-js/react";
import { useLocation } from "@reach/router"; // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import Cookies from "js-cookie";
import Grid from "./layout/Grid";
import Row from "./layout/Row";
import Cell from "./layout/Cell";
import Button from "./Button";
import { H2, P } from "./texts";
import cookieSvg from "../images/cookie.svg";

export const setCookies = ({ allow = false, location, callback }) => {
  Cookies.set("cookie-consent", true);
  Cookies.set("gatsby-gdpr-google-analytics", allow);
  Cookies.set("gatsby-gdpr-youtube", allow);
  initializeAndTrack(location);
  if (typeof callback === "function") {
    callback();
  }
};

const Banner = withTheme(({ theme, onAccept, onDecline, locale }) => {
  const {
    allTranslation: { edges },
  } = useStaticQuery(graphql`
    query CookieConsentQuery {
      allTranslation(filter: { meta: { domain: { eq: "components" } } }) {
        edges {
          node {
            meta {
              language
            }
            data {
              cookieConsent {
                headline
                text
                decline
                accept
              }
            }
          }
        }
      }
    }
  `);
  const { headline, text, decline, accept } =
    edges?.find((edge) => edge.node.meta.language === locale)?.node?.data
      ?.cookieConsent || {};
  return (
    <Grid
      css={css`
        background: $color-spot2;
        border-radius: 30px;
        margin: calc($grid-gutter * -1);
        padding: $grid-gutter;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.28);
      `(theme)}
    >
      <Row>
        <Cell
          offset={{ default: 2 / 10, xl: 0 }}
          span={{ default: 6 / 10, xl: 3 / 10 }}
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <img
            src={cookieSvg}
            width={249}
            height={230}
            css={css`
              display: block;
              width: 100%;
              max-width: 166px;

              @media:xl {
                width: 71.143%;
                max-width: 249px;
              }
            `(theme)}
            alt=""
          />
        </Cell>
        <Cell
          span={{ xl: 6 / 10 }}
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
          `}
        >
          <H2>
            {headline.map((text) => (
              <span
                key={text}
                css={css`
                  display: block;
                `}
              >
                {text}
              </span>
            ))}
          </H2>
          <P css={css`$mt1;`(theme)}>{text}</P>
          <div
            css={css`
              $mt4;
              display: flex;
              justify-content: flex-end;
            `(theme)}
          >
            <Button onClick={onDecline} small>
              {decline}
            </Button>
            <Button
              onClick={onAccept}
              small
              css={css`
                $ml2;
                color: $color-bg;
                border-color: $color-spot1;
                background: $color-spot1;
              `(theme)}
            >
              {accept}
            </Button>
          </div>
        </Cell>
      </Row>
    </Grid>
  );
});

const CookieBanner = withTheme(({ theme, locale }) => {
  const [showBanner, setShowBanner] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    const cookieValue = Cookies.get("cookie-consent");
    if (typeof cookieValue === "undefined") {
      setShowBanner(true);
    } else {
      initializeAndTrack(location);
    }
  }, [location]);

  const decline = () =>
    setCookies({
      allow: false,
      location,
      callback: () => {
        setShowBanner(false);
      },
    });
  const accept = () =>
    setCookies({
      allow: true,
      location,
      callback: () => {
        setShowBanner(false);
      },
    });
  if (!showBanner) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        css={css`
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 9999999998;
          background: rgba(255, 255, 255, 0.8);
        `(theme)}
        aria-hidden="true"
      />
      <Grid
        css={css`
          position: fixed;
          left: 10px;
          right: 0;
          bottom: 40px;
          z-index: 9999999999;

          @media:xl {
            left: 80px;
            bottom: 100px;
          }
        `(theme)}
      >
        <Row>
          <Cell offset={{ default: 1 / 12 }} span={{ default: 10 / 12 }}>
            <Banner onDecline={decline} onAccept={accept} locale={locale} />
          </Cell>
        </Row>
      </Grid>
    </React.Fragment>
  );
});

export default CookieBanner;
