import { css, withTheme } from "@delight-js/react";
import isPropValid from "@emotion/is-prop-valid";
import styled from "@delight-js/styled";

const basicStyle = (props) =>
  css`
      $layerBorder;
      $textCaption;
      border-color: currentColor;
      box-sizing: border-box;
      display: inline-block;
      line-height: 22px;
      color: $color-spot1;
      padding: 0 $space-3;
      text-decoration: inherit;
      transition: box-shadow .2s;
      outline: 0;
      background: transparent;
      cursor: pointer;
      box-shadow: none;
      text-align: center;
      transition: box-shadow .2s;

      @media:lg {
        $textButton;
        padding: 15px 27px;
      }
      &:hover {
        box-shadow: inset 0px 0 0px 2px currentColor;
      }
      &:focus, &:focus-visible {
        box-shadow: 0 0 0 6px rgba(0,222,202, 0.6);

        &:hover {
          box-shadow: 0 0 0 6px rgba(0,222,202, 0.6), inset 0px 0 0px 2px currentColor;
        }
      }
      &:focus:not(:focus-visible) {
        box-shadow: 0 0 0 0 rgba(0,222,202, 0.6);
        &:hover {
          box-shadow: inset 0px 0 0px 2px currentColor;
        }
      }
      &[disabled] {
        color: $color-neutral;
        cursor: not-allowed;
        &:hover {
          box-shadow: none;
        }
      }
`(props.theme);

const Button = withTheme(
  styled("button", {
    shouldForwardProp: (prop) => prop === "url" || isPropValid(prop),
  })(
    basicStyle,
    ({ small }) =>
      !small &&
      `
      @media screen and (min-width: 768px) {
          padding: 27px 47px;
      }`
  )
);

export default Button;
