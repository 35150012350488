/** @jsx jsx */
import { jsx, css, withTheme } from "@delight-js/react";

const components = [
  { name: "P", tag: "p", cls: "$text $textBody $textBody1" },
  { name: "P2", tag: "p", cls: "$text $textBody $textBody2" },
  { name: "H1", tag: "h1", cls: "$text $textHeadline $textH1" },
  { name: "H2", tag: "h2", cls: "$text $textHeadline $textH2" },
  { name: "H3", tag: "h3", cls: "$text $textHeadline $textH3" },
  { name: "H4", tag: "h4", cls: "$text $textHeadline $textH4" },
  { name: "H5", tag: "h5", cls: "$text $textHeadline $textH5" },
  { name: "H6", tag: "h6", cls: "$text $textHeadline $textH6" },
  { name: "Overline", tag: "h3", cls: "$text $textOverline" },
  { name: "Subtitle1", tag: "h3", cls: "$text $textSubtitle1" },
  { name: "Subtitle2", tag: "p", cls: "$text $textSubtitle2" },
  { name: "Caption", tag: "p", cls: "$text $textCaption" },
  {
    name: "List",
    tag: "ul",
    cls: `
    $text;
    $textBody;
    $textBody1;
    list-style: none;
    margin: 0;
    padding: 0 0 0 1.2em;
    `,
  },
  {
    name: "ListItem",
    tag: "li",
    cls: `
    margin: 0;
    padding: 0;
    &:before {
      content: "—";
      float: left;
      margin-left: -1.2em;
    }
    `,
  },
];

const createComponent = ({ tag, cls }) =>
  withTheme(({ theme, children, as: Tag = tag, ...props }) => (
    <Tag css={css(cls)(theme)} {...props}>
      {children}
    </Tag>
  ));

const {
  P,
  P2,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Overline,
  Subtitle1,
  Subtitle2,
  Caption,
  List,
  ListItem,
} = components.reduce((acc, cur) => {
  const Comp = createComponent(cur);
  Comp.displayName = cur.name;
  return { ...acc, [cur.name]: Comp };
}, {});
export {
  P,
  P2,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Overline,
  Subtitle1,
  Subtitle2,
  Caption,
  List,
  ListItem,
};
