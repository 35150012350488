import * as React from "react";
import { Link as GatsbyLink, StaticQuery, graphql } from "gatsby";

const isExternalLink = (url, base) =>
  !url.startsWith(base) && !url.startsWith("/");

const LinkWithData = ({ data, url, ...props }) => {
  const baseUrl = data.site.siteMetadata.url;
  const newProps = {
    ...props,
  };
  if (url) {
    if (isExternalLink(url, baseUrl)) {
      newProps.href = url;
    } else {
      newProps.to = url.replace(baseUrl, "");
    }
  }
  const isExternal = !newProps.to;
  const Comp = isExternal ? "a" : GatsbyLink;

  if (isExternal) {
    newProps.rel = "noreferrer";
    newProps.target = "_blank";
  }
  return <Comp {...newProps} />;
};

const Link = (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            url
          }
        }
      }
    `}
    render={(data) => <LinkWithData {...props} data={data} />}
  />
);

export default Link;
