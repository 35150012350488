import * as React from "react";
/** @jsx jsx */
import { jsx, withTheme } from "@delight-js/react";

import Row from "./Row";
import Cell from "./Cell";

const Grid = React.forwardRef(
  ({ theme, children, as: Tag = "div", ...props }, ref) => (
    <Tag {...props} ref={ref}>
      {children}
    </Tag>
  )
);

export { Row, Cell };

export default withTheme(Grid);
