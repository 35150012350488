import themeConfig from "./abstracts/config";
import colors from "./abstracts/colors";
import fonts from "./abstracts/fonts";
import grid from "./abstracts/grid";
import breakpoints from "./abstracts/breakpoints";
import space from "./abstracts/spacing";

// const adpativeText = (responsiveStyles, breakpoints) => {
//   return ``;
// };

const { styles: spacingStyles, ...spaceProps } = space;

const input = [themeConfig, breakpoints, colors, fonts, grid, spaceProps];

const d = input.reduce(
  (acc, cur) => ({
    ...acc,
    [cur.config.domain]: cur.map,
  }),
  {}
);

const base = {
  vars: input
    .filter((i) => !!i.config.createCssVariables)
    .map((i) => ({ prefix: i.config.domain, vars: i.vars })),
  styles: {
    "::selection": `
      background-color: $color-spot2alpha;
    `,

    ".visually-hidden": `
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    `,
    body: `
      color: $color-text;
      background: $color-bg;
      font-family: $font-text;
      font-weight: normal;
      overflow-x: hidden;
      margin: 0 0 0 10px;

      * {
        margin: 0;
      }
      @media:xl {
        margin-left: 80px; 
      }
      `,
    "h1, h2, h3, h4, h5, h6": `
      font-family: $font-headline;
    `,
    "#___gatsby": `
      overflow: hidden;
    `,
    "p a": `
      text-decoration: none;
      color: inherit;
      transition: box-shadow 0.25s ease-out;
      box-shadow: inset 0 -2px 0 0px $color-spot2;
      &:hover {
        box-shadow: inset 0 -1.3em 0 0px $color-spot2alpha, 0 3px $color-spot2alpha;
      }
    `,
  },
};

const uiStyles = {
  text: `
  padding: 0;
  // &:before, &:after {
  //   content: "";
  //   display: block;
  // }
  `,
  textHeadline: `
    position:relative;
    top: 0;
    left: -0.08em;
    font-family: $font-headline;
    font-weight: normal;
    color: $color-spot1;
  `,

  textH1: `
    letter-spacing: -0.015625em;
    font-size: {35,35,,,,96}({"step": 1, "method": "floor"})px;
    line-height: {40,40,,,,104}({"step": 8, "method": "ceil"})px;
  `,
  textH2: `
    font-size: {32,32,,,,60}({"step": 1, "method": "floor"})px;
    line-height: {40,40,,,,72}({"step": 8, "method": "ceil"})px;
  `,
  textH3: `
  font-size: {28,28,,,,48}({"step": 1, "method": "floor"})px;
  line-height: {36,36,,,,56}({"step": 8, "method": "ceil"})px;
  `,
  textH4: `
  font-size: {25,25,,,,34}({"step": 1, "method": "floor"})px;
  line-height: {28,28,,,32,40}({"step": 8, "method": "ceil"})px;
  `,
  textH5: `
    font-size: 24px;
    line-height: 24px;
  `,
  textH6: `
    font-size: 20px;
    line-height: 24px;
  `,
  textSubtitle1: `
    font-family: $font-subline;
    font-weight: 400;
    letter-spacing: 0.0094em;

    font-size: {10,10,,,,18}({"step": 1, "method": "floor"})px;
    line-height: {16,16,,,,24}({"step": 8, "method": "ceil"})px;
  `,
  textSubtitle2: `
    font-family: $font-subline;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.0094em;
  `,
  textBody: `
  font-family: $font-text;
  font-weight: 400;
  letter-spacing: 0.012em;
  `,
  textBody1: `
  font-size: {14,14,,,,18}({"step": 1, "method": "floor"})px;
  line-height: {24,24,,,,32}({"step": 8, "method": "round"})px;
  `,
  textBody2: `
  font-size: {12,12,,,,16}({"step": 1, "method": "floor"})px;
  line-height: {24,24,,,,32}({"step": 8, "method": "round"})px;
  `,
  textButton: `
  font-family: $font-button;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.0144em;
  text-decoration: none;
  `,
  textCaption: `
    font-family: $font-interface;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.00833em;
  `,
  textOverline: `
    color: $color-spot1;
    font-family: $font-interface;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  `,
  layerCard: `
    background: $color-bg2;
  `,
  layerBorder: `
  border: solid 1px $color-spot1;
  border-radius: 50px;
  &:focus {
    outline: none;
    box-shadow: 0 0 8px rgba($color-spot2, .6);
  }
  `,

  ...spacingStyles,
};

const utils = {
  styles: input.reduce((acc, cur) => {
    if (!cur.styles) {
      return acc;
    }
    return {
      ...acc,
      ...cur.styles,
    };
  }, {}),
};

const theme = {
  definitions: { ...d },
  base,
  ui: {
    ...uiStyles,
  },
  utils,
};

export default theme;
