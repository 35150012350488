import * as React from "react";
import { getScrollbarWidth } from "./useScrollbarWidth";

const safeDocument = typeof document !== "undefined" ? document : {};
const { documentElement: html, body } = safeDocument;

/**
 * Source: https://gist.github.com/reecelucas/2f510e6b8504008deaaa52732202d2da
 * Usage:
 * const [blockScroll, allowScroll] = useScrollBlock();
 */
const useBlockScrolling = () => {
  const scrollBlocked = React.useRef();

  const setHidden = (hidden = true) => {
    [html, body].forEach((elem) => {
      elem.style.position = hidden ? "relative" : "";
      elem.style.overflow = hidden ? "hidden" : "";
    });
  };

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;

    const bodyPaddingRight =
      (parseInt(
        window.getComputedStyle(body).getPropertyValue("padding-right")
      ) || 0) + getScrollbarWidth();
    setHidden();
    body.style.paddingRight = `${bodyPaddingRight}px`;
    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;

    setHidden(false);
    body.style.paddingRight = "";

    scrollBlocked.current = false;
  };

  const toggleScroll = (block) => {
    (typeof block !== "undefined" ? block : scrollBlocked.current)
      ? blockScroll()
      : allowScroll();
  };

  return toggleScroll;
};

export default useBlockScrolling;
