import * as React from "react";
import { css, withTheme } from "@delight-js/react";

const keyframes = [
  {
    dashLength: 22,
    strokeDashoffset: [0, 0],
    x: 24,
    width: 20,
  },
  {
    dashLength: 25.46,
    strokeDashoffset: [-63, -78],
    x: 44,
    width: 0,
  },
  // {
  //     dashLength: 22,
  //     strokeDashoffset: [-122, -133],
  //     x: 27,
  //     width: 20
  // }
];

const MenuButton = withTheme(({ theme, switchedOn = false, ...props }) => {
  const frame = keyframes[switchedOn ? 1 : 0];
  const strokeDasharray = `${frame.dashLength},1000`;
  const offset = frame.strokeDashoffset;
  const path1 = {
    strokeDasharray,
    strokeDashoffset: offset[0],
  };
  const path2 = {
    strokeDasharray,
    strokeDashoffset: offset[1],
  };
  const path3 = {
    x: frame.x,
    width: frame.width,
  };
  return (
    <button
      css={css`
        width: 48px;
        height: 48px;
        margin: 0;
        padding: 0;
        border: none;
        background-color: rgba(255, 255, 255, 0.85);
        border-radius: 32px;
        backdrop-filter: blur(6px);
        cursor: pointer;
        backface-visibility: hidden;
        transition: background-color 0.3s;

        @media:md {
          width: 64px;
          height: 64px;
        }
      `(theme)}
      style={
        switchedOn
          ? {
              backgroundColor: "transparent",
            }
          : {}
      }
      {...props}
      aria-label="Menu"
    >
      <svg
        width="64px"
        height="64px"
        viewBox="0 0 64 64"
        css={css`
          display: block;
          width: 100%;
          height: 100%;
          > * {
            transition: 0.6s;
          }
          path {
            fill: none;
            stroke-linecap: square;
            stroke-width: 2;
            stroke: $color-spot1;
          }
          rect {
            fill: $color-spot1;
          }
        `(theme)}
      >
        <path
          d="M43,40l-22,0c-6,0-12-4.8-12-14s7-10,14-3c11.5,11.6,17.5,17.6,18,18c6,6,10.6,0.1,11-6c0.5-7.9-3.2-11-9-11c-7.2,0-14.5,0-22,0"
          {...path1}
        />
        <path
          d="M21,24h22c6,0,8,1.4,7,6c-1,4.4-6.2,11.9-14.1,15.2C19.8,52,16.6,47.2,23,41l18-18c5-5,9.3,3.3,9.3,8.1S48.4,40,43,40H21"
          {...path2}
        />
        <rect y="31" height="2" {...path3} />
      </svg>
    </button>
  );
});

export default MenuButton;
