/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
// import useSSR from "use-ssr";

function Seo({
  title,
  pagination = {},
  meta = {},
  lang,
  localizations = [],
  ...props
}) {
  const {
    site: { siteMetadata: fallback },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitterHandle
            url
          }
        }
      }
    `
  );

  const createLocale = (lang = "") =>
    `${lang}_${lang === "en" ? "US" : lang.toUpperCase()}`;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${fallback.title}`}
      meta={[
        ...[/*"keywords"*/ "author", "description"].map((term) => ({
          name: term,
          content: meta[term] || fallback[term],
        })),
        {
          name: `robots`,
          content: `max-image-preview:standard`,
        },
        {
          property: `og:title`,
          content: meta.title || title || fallback.title,
        },
        {
          property: `og:site_name`,
          content: fallback.title,
        },
        {
          property: `og:locale`,
          content: createLocale(lang),
        },
        ...localizations
          .filter((t) => t.locale !== lang)
          .map((t) => ({
            property: `og:locale:alternate`,
            content: createLocale(t.locale),
          })),
        {
          property: `og:image`,
          content: meta.sharingImageFacebook?.src,
        },
        {
          property: `og:image:width`,
          content: meta.sharingImageFacebook?.width,
        },
        {
          property: `og:image:height`,
          content: meta.sharingImageFacebook?.height,
        },
        {
          property: `og:description`,
          content: meta.description || fallback.description,
        },
        {
          property: `og:type`,
          content: meta.type || `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:title`,
          content: meta.title || title || fallback.title,
        },
        {
          name: `twitter:image`,
          content: meta.sharingImageTwitter?.src,
        },
        {
          name: `twitter:creator`,
          content: fallback.twitterHandle,
        },
      ]}
      link={[
        ...localizations
          .filter((t) => t.locale !== lang)
          .map((t) => ({
            rel: "alternate",
            hreflang: t.locale,
            href: t.fullUrl,
            title: t.title,
          })),
        {
          rel: "alternate",
          type: "application/rss+xml",
          title: `${fallback.title} (${lang})`,
          href: `${fallback.url}/${lang}/rss.xml`,
        },
      ]}
    >
      {!!pagination.previousLink && (
        <link rel="prev" href={pagination.previousLink} />
      )}
      {!!pagination.nextLink && <link rel="next" href={pagination.nextLink} />}
    </Helmet>
  );
}

export default Seo;
